import React from 'react'

export default function Header() {
    return (
        <header className="header">
            <img src="/logo.png" className="logo" alt="logo" />
            <div className="menu-list">
                <a href="#expert">
                    WHAT WE DO
                </a>
                <a href="#about">
                    ABOUT US
                </a>
                <a href="#contact">
                    CONTACT US
                </a>
            </div>
        </header>
    )
}
