import React from "react"
import Footer from "./footer"
import LandingSection from "./landing"
import InfoSection from "./info"
import { Helmet } from "react-helmet"

export default function Home() {
  return (
    <div className="application">
      <Helmet>
        <title>NGU Lawyers</title>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <meta name="title" content="NGU Lawyers" />
        <meta name="description" content="Our  firm  is  a  committed,  resourceful  group of cutting edge lawyers with real world commercial acumen. Our mission is to understand our clients business operations and their challenges and provide tailored legal solutions to those challenges in both a legal and commercially viable sense.   We believe that by taking the time to understand our client’s business operations in detail we can provide exemplary legal solutions for the reality of the commercial world that will give our clients the edge over their adversaries and ammunition needed to conquer real world commercial challenges." />
      </Helmet>
      <div className="page-container">
        <LandingSection />
        <InfoSection />
        <Footer />
      </div>
    </div>
  );
}