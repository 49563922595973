import React from 'react'
import Header from './header'

export default function LandingSection() {
    return (
        <div className="landing-section">
            <Header />
            <div className="landing-section-content">
                <h2 className="landing-section-content-title">
                    Real world experience | Cutting edge legal expertise
                </h2>
            </div>
        </div>
    )
}
