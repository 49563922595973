import React from 'react'

export default function Footer() {
    return (
        <div>
            <div className="footer-section" id="contact">
                <footer className="footer">
                <h4 className="footer-title">
                    Contact Us
                </h4>
                <a href="tel:+61731023052" className="phone-button footer-button">
                    <p className="button-title">
                        Phone
                    </p>
                    <p className="button-text">
                        (+61) 7 3102 3052
                    </p>
                </a>
                <a href="mailto:info@ngulawyers.com.au" className="email-button footer-button">
                    <p className="button-title">
                        Email
                    </p>
                    <p className="button-text">
                        info@ngulawyers.com.au
                    </p>
                </a>
                </footer>
            </div>
            <div className="copyright-bit">
                NGU Lawyers Pty Ltd ACN 605 377 188 | Limited liability by a scheme approved under professional standards legislation © 2021 NGU Lawyers
            </div>
        </div>
    )
}