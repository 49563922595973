import React from 'react'

export default function InfoSection() {
    return (
        <div class="info-section" id="expert">
            <div class="icons-wrapper">
                <img src="/copyright.png" className="icon" alt="icon"/>
                <img src="/briefcase.png" className="icon" alt="icon"/>
                <img src="/laptop.png" className="icon" alt="icon"/>
                <img src="/house.png" className="icon" alt="icon"/>
            </div>
            <div className="expertise-text-wrapper">
                <p className="expertise-text-title">
                    With a range of experience and expertise.
                </p>
                <p className="expertise-text">
                    Including corporate and commercial law, technology law, and property law.
                </p>
            </div>
            <div className="startup-section">
                <div className="startup-text-wrapper">
                <h3 className="startup-text-title">
                    Startup business?
                </h3>
                <p className="startup-text">
                    Let us help you take full advantage of government incentives available to you.
                </p>
                </div>
            </div>
            <div className="about-text-wrapper" id="about">
                <h4 className="about-text-title">
                    About NGU Lawyers
                </h4>
                <p className="about-text">
                    Our  firm  is  a  committed,  resourceful  group of cutting edge lawyers with real world commercial acumen. Our mission is to understand our clients business operations and their challenges and provide tailored legal solutions to those challenges in both a legal and commercially viable sense.   We believe that by taking the time to understand our client’s business operations in detail we can provide exemplary legal solutions for the reality of the commercial world that will give our clients the edge over their adversaries and ammunition needed to conquer real world commercial challenges.
                </p>
            </div>
        </div>
    )
}
